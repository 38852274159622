import { createI18n } from 'vue-i18n'
import zh_cn from './zh_cn.js'
import zh_tw from './zh_tw.js'
import en from './en.js'
import vi from './vi.js'

const i18n = createI18n({
  // 默认语言
  locale: 'zh_cn',
  messages: {
    zh_cn,
    zh_tw,
    en,
    vi,
  },
})
export default i18n