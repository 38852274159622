export default {
  app: {
    chinaTime: '中国时间',
    exchangeRate: '汇率',
  },
  productSearch: {
    platform: '平台'
  },
  pageFooter: {
    tel: '电话',
    moblie: '手机',
    weChat: '微信',
    email: '邮箱',
    howToSearch: '如何搜索产品',
    howToPay: '如何付款',
    howToUse: '采购流程',
    trackFree: '物流费用',
    deliverType: '配送方式',
    trackTime: '物流时间',

  },
  pageMenu: {
    message: '我的消息',
    purchase: '采购订单',
    billcode: '我的包裹',
    transite: '转运订单',
    balance: '余额管理',
    account: '账户设置',
    loginOut: '退出登录'
  },
  login: {
    title: '登录',
    slogan: '登录开启您的外贸业务',
    account: '账户',
    accountTip: '请输入用户名或者手机号码!',
    pwd: '密码',
    pwdTip: '请输入登录密码!',
    forgetPwd: '忘记密码',
    submit: '登录',
    noAccount: '还没有账户？',
    goRegister: '前往注册'
  },
  register: {
    title: '注册',
    country: '目的地',
    countryTip: '请选择目的地!',
    phone: '手机号码',
    phoneTip: '请输入手机号码!',
    email: '邮箱',
    emailTip: '请输入邮箱地址!',
    pwd: '密码',
    pwdTip: '请输入注册密码!',
    recommender: '推荐人',
    recommenderTip: '请输入推荐人会员编号!',
    source: '来源',
    sourceTip: '请输入注册来源!',
    submit: '注册',
    returnLogin: '返回登录'
  },
  payCommon: {

  }
}