import { createStore } from "vuex";
import i18n from "@/lang/index.js";

export default createStore({
  state: {
    locale: window.localStorage.getItem("Locale") || i18n.global.locale,
    token: null,
    userInfo: JSON.parse(window.localStorage.getItem("UserInfo")) || null,
    shopInfo: JSON.parse(window.localStorage.getItem("ShopInfo")) || null,
    billCodeInfo: JSON.parse(window.localStorage.getItem("BillCodeInfo")) || null,
  },
  getters: {
    shopInfo: function (state) {
      return state.shopInfo
    }
  },
  mutations: {
    saveLocale (state, data) {
      if (data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.locale = data;
      window.localStorage.setItem("Locale", data);
    },
    saveToken (state, data) {
      if (data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.token = data;
      window.localStorage.setItem("Token", data);
    },
    saveShopInfo (state, data) {
      if (data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.shopInfo = data;
      window.localStorage.setItem("ShopInfo", JSON.stringify(data));
    },
    saveUserInfo (state, data) {
      if (data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.userInfo = data;
      window.localStorage.setItem("UserInfo", JSON.stringify(data));
    },
    saveBillCodeInfo (state, data) {
      if (data == null || data == "" || data == undefined || data == "null" || data == "undefined") {
        data = null;
      }
      state.billCodeInfo = data;
      window.localStorage.setItem("BillCodeInfo", JSON.stringify(data));
    },
  },
  actions: {
  },
  modules: {
  },
});
