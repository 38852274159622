export default {
  app: {
    chinaTime: '中國時間',
    exchangeRate: '匯率'
  },
  productSearch: {
    platform: '平台'
  },
  pageMenu: {
    message: '我的消息',
    purchase: '采購訂單',
    billcode: '我的包裹',
    transite: '轉運訂單',
    balance: '餘額管理',
    account: '賬戶設置',
    loginOut: '退出登錄'
  },
  login: {
    title: '登錄',
    slogan: '登錄開啟您的外貿業務',
    account: '賬戶',
    accountTip: '請輸入用戶名或者手機號碼!',
    pwd: '密碼',
    pwdTip: '請輸入登錄密碼!',
    forgetPwd: '忘記密碼',
    submit: '登錄',
    noAccount: '還沒有賬戶?',
    goRegister: '前往註冊'
  },
  register: {
    title: '註冊',
    country: '目的地',
    countryTip: '請選擇目的地!',
    phone: '手機號碼',
    phoneTip: '請輸入手機號碼!',
    email: '郵箱',
    emailTip: '請輸入郵箱地址!',
    pwd: '密碼',
    pwdTip: '請輸入註冊密碼!',
    recommender: '推薦人',
    recommenderTip: '請輸入推薦人會員編號!',
    source: '來源',
    sourceTip: '請選擇註冊來源!',
    submit: '註冊',
    returnLogin: '返回登錄'
  }
}