<template>
  <div class="tp-page-bread" v-if="showBreadcrumb">
    <div class="main-content">
      <div class="left-area">
        <div class="breadcrumbs">
          <div class="breadcrumb-item" @click="jumpToHome">首页</div>
          <div class="breadcrumb-separator"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"
              viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" class="tabler-icon tabler-icon-chevron-right">
              <path d="M9 6l6 6l-6 6"></path>
            </svg></div>
          <div class="breadcrumb-item">{{ this.$route.meta.title ?? this.$route.query.title }}</div>
        </div>
      </div>
      <div class="right-area">
        <button class="contact-us-button-root" type="button" data-button="true" aria-haspopup="menu"
          aria-expanded="false" aria-controls="mantine-6bqk1okav-dropdown" id="mantine-6bqk1okav-target">
          <div class="button-inner">
            <span class="button-left-icon"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                viewBox="0 0 24 24" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7h10v2H7zm0 4h7v2H7z"></path>
                <path
                  d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z">
                </path>
              </svg></span><span class="button-label">联系客服</span>
          </div>
        </button>
      </div>
      <!-- <div style="width:100%;" class="tp-page-bread">
        <a-row justify="center">
          <a-col :span="16" class="tp-page-bread-item">
            <div><router-link to="/" class="link">首页</router-link> </div> >
            <div>{{ msg }}</div>
          </a-col>
        </a-row>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageBread',
  props: {
    msg: String,
    showBreadcrumb: { type: Boolean, default: false }
  },
  methods: {
    jumpToHome () {
      this.$router.push({ name: "home" });
      this.$root.updateBreadcrumbVisible(false);
    }
  }
}
</script>

<style lang="scss">
.tp-page-bread {
  width: 100%;
  height: var(--content-header-height);
  background-color: var(--main-color);
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.tp-page-bread .main-content {
  min-width: var(--effective-width);
  max-width: var(--effective-width);
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 30px;
  overflow: hidden;
}

.tp-page-bread .main-content .left-area {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  margin-right: 10px;
}

.tp-page-bread .main-content .right-area {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
}

.breadcrumbs {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.tp-page-bread .main-content .left-area .breadcrumbs .breadcrumb-item {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  cursor: pointer;
  max-width: 540px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumb-separator {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-tap-highlight-color: transparent;
  font-size: 0.875rem;
  text-decoration: none;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  color: rgb(73, 80, 87);
  line-height: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.tp-page-bread .main-content .left-area .breadcrumbs .breadcrumb-iem:last-child {
  font-weight: 600;
  cursor: default;
}

.contact-us-button-root {
  padding: 0px 1.125rem 0px calc(0.75rem);
  appearance: none;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  height: 2.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  width: auto;
  border-radius: 6px;
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 0.875rem;
  user-select: none;
  cursor: pointer;
  border: 0.0625rem solid transparent;
  background-color: rgb(249, 249, 251);
  color: var(--main-color);
}

.button-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
  overflow: visible;
}

.button-left-icon {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.625rem;
}

.button-label {
  white-space: nowrap;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>