import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import moment from 'moment'
import App from './App.vue'
import i18n from "./lang/index.js"
import router from './router'
import store from './store'
import 'ant-design-vue/dist/reset.css';
// 引用API文件
import api from './api/http.js'

const app = createApp(App)

app.use(store).use(router).use(Antd).use(i18n).mount("#app")
app.config.globalProperties.$api = api
app.config.globalProperties.$moment = moment

