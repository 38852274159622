import { createRouter, createWebHashHistory } from 'vue-router'
// 引入进度条插件
import NProgress from 'nprogress'
// 引入对应css样式
import 'nprogress/nprogress.css'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'facilbuy',
      requireAuth: false,
    },
  }
  , {
    path: '/how-to-use',
    name: 'how-to-use',
    component: () => import('../views/HowToUseView.vue'),
    meta: {
      title: '如何使用',
      requireAuth: false,
    },
  }
  , {
    path: '/transportation-charge',
    name: 'transportation-charge',
    component: () => import('../views/TransportationChargeView.vue'),
    meta: {
      title: '物流转运费用',
      requireAuth: false,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      title: '关于我们',
      requireAuth: false,
    },
  },
  {
    path: '/product_search',
    name: 'product_search',
    component: () => import('../views/ProductSearchView.vue'),
    meta: {
      title: '搜索结果',
      hasMenu: true,
      requireAuth: true,
    }
  },
  {
    path: '/product_detail',
    name: 'product_detail',
    component: () => import('../views/ProductDetailView.vue'),
    meta: {
      hasMenu: true,
      requireAuth: true,
    }
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  }
  , {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {
      title: '注册',
      hasMenu: false,
      requireAuth: false,
    }
  }
  , {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPwdView.vue'),
    meta: {
      requireAuth: false,
    },
  }
  , {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/CartView.vue'),
    meta: {
      requireAuth: true,
    },
  }
  , {
    path: '/message',
    name: 'message',
    component: () => import('../views/MessageView.vue'),
    meta: {
      title: '我的消息',
      hasMenu: true,
      requireAuth: true,
    }
  }

  , {
    path: '/purchase',
    name: 'purchase',
    component: () => import('../views/PurchaseView.vue'),
    meta: {
      title: '采购订单',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/purchase-detail',
    name: 'purchase-detail',
    component: () => import('../views/PurchaseDetailView.vue'),
    meta: {
      title: '采购订单详情',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('../views/WareHouseView.vue'),
    meta: {
      title: '集运仓库',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/my-billcode',
    name: 'my-billcode',
    component: () => import('../views/MyBillCodeView.vue'),
    meta: {
      title: '我的包裹',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/purchase-order',
    name: 'purchase-order',
    component: () => import('../views/PurchaseOrderView.vue'),
    meta: {
      title: '订单详情',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/transite',
    name: 'transite',
    component: () => import('../views/TransiteView.vue'),
    meta: {
      title: '转运订单',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/transite-detail',
    name: 'transite-detail',
    component: () => import('../views/TransiteDetailView.vue'),
    meta: {
      title: '转运订单详情',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/pending-payments',
    name: 'pending-payments',
    component: () => import('../views/PendingPaymentsView.vue'),
    meta: {
      title: '批量付款',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/balance',
    name: 'balance',
    component: () => import('../views/BalanceView.vue'),
    meta: {
      title: '余额管理',
      hasMenu: true,
      requireAuth: true,
    }
  }
  , {
    path: '/account',
    name: 'account',
    component: () => import('../views/AccountView.vue'),
    meta: {
      title: '账户管理',
      hasMenu: true,
      requireAuth: true,
    }
  },
  {
    path: '/pay-common',
    name: 'pay-common',
    component: () => import('../views/PayCommon.vue'),
    meta: {
      title: '通用支付',
      hasMenu: true,
      requireAuth: true,
    }
  },
  {
    path: '/pay-success',
    name: 'pay-success',
    component: () => import('../views/PaySuccess.vue'),
    meta: {
      title: '支付成功',
      hasMenu: true,
      requireAuth: true,
    }
  }


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 修改进度条插件的配置
NProgress.configure({
  showSpinner: false
})

import store from "@/store";
var storeTemp = store;
// 前置首位 访问权限控制
router.beforeEach((to, from, next) => {
  // 开启页面进度条
  NProgress.start()
  // 用户仓库
  //const store = useUserStore()
  // 用户白名单
  //const wihteList = ['/login']
  // 没有token 并且 不再白名单 则跳转登录页
  //if (!store.user?.token && !wihteList.includes(to.path)) return '/login'
  // 放行 return true  可以不用写
  if (!storeTemp.state.userInfo) {
    storeTemp.commit("saveUserInfo", window.localStorage.UserInfo ? JSON.parse(window.localStorage.UserInfo) : window.localStorage.UserInfo);
  }
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (storeTemp.state.userInfo) {
      // 通过vuex state获取当前的token是否存在
      next();
    } else {
      debugger
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }

})
const breadcrumbsStruct = []
// 后置守卫
router.afterEach((to, from) => {
  // 设置页面标题
  document.title = to.meta.title || 'test'
  NProgress.done()
  console.log(to);
})

export default router
