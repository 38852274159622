// 配置API接口地址
import md5 from './md5.js'

// var root = 'http://apk.neters.club/api'
// var root = 'https://test.facilbuy.com/PlatForm'
var root = "/PlatForm";//用proxy实现本地代理跨域（生产环境使用的是nginx）
// 引用axios
const axios = require('axios')
// 自定义判断元素类型JS
function toType(obj) {
  return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull(o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}
/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/

function apiAxios(method, url, params, success, failure) {
  if (params) {
    params = filterNull(params)
  }
  axios({
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,　　 //headers 是即将被发送的自定义请求头，还记得我们的jwt验证么，可以封装进来，注意!这里如果要添加 headers ，一定要是正确的值　　 headers:{"Authorization":"Bearer xxxxxxx"},
    baseURL: root,
    withCredentials: false
  })
    .then(function (res) {
      if (res.status == 200 && res.data.State) {
        if (success) {
          success(res.data)
        }
      } else {
        if (failure) {
          failure(res.data)
        } else {
          window.alert('error: ' + JSON.stringify(res.data))
        }
      }
    })
    .catch(function (err) {
      let res = err.response
      if (res) {
        window.alert('api error, HTTP CODE: ' + res.status)
      }
    })
}



// 返回在vue模板中的调用接口
export default {
  get: function (url, params, success, failure) {
    return apiAxios('GET', url, params, success, failure)
  },
  post: function (url, params, success, failure) {
    return apiAxios('POST', url, params, success, failure)
  },
  put: function (url, params, success, failure) {
    return apiAxios('PUT', url, params, success, failure)
  },
  delete: function (url, params, success, failure) {
    return apiAxios('DELETE', url, params, success, failure)
  },
  tp_request: function (options, success, failure) {
    let _config = {
      Url: 'AllTransfer',
      CusID: '1000',
      Key: '123456'
    }
    let JsonData = encodeURI(JSON.stringify(options.data));
    let keymd5 = md5(JsonData + _config.Key);
    let req_data = {
      FunctionName: options.name,
      CusID: _config.CusID,
      JsonData: JsonData,
      KeyMd5: keymd5
    }
    return apiAxios('POST', _config.Url, req_data, success, failure)


    // return new Promise((resolve, reject) => {
    //   let _config = {
    //     Url: 'https://test.facilbuy.com/PlatForm/AllTransfer',
    //     CusID: '1000',
    //     Key: '123456'
    //   }
    //   let JsonData = encodeURI(JSON.stringify(options.data));
    //   let keymd5 = md5(JsonData + _config.Key);
    //   let req_data = {
    //     FunctionName: options.name,
    //     CusID: _config.CusID,
    //     JsonData: JsonData,
    //     KeyMd5: keymd5
    //   }

    //   axios({
    //     method: 'POST',
    //     url: _config.Url,
    //     data: req_data,
    //     params: null,　　 //headers 是即将被发送的自定义请求头，还记得我们的jwt验证么，可以封装进来，注意!这里如果要添加 headers ，一定要是正确的值　　 
    //     headers: { 'Content-Type': 'application/json' },
    //     baseURL: root,
    //     withCredentials: false
    //   })
    //     .then(function (res) {
    //       resolve(res)
    //     })
    //     .catch(function (err) {
    //       reject(err)
    //     })
    // })
  },

}