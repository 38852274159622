<template>
  <a-config-provider :locale="locale">
    <div class="tp-body">
      <!-- 页头 -->
      <div class="tp-header tp-head">
        <!-- 汇率 -->
        <div class="header-container-top tp-top">
          <div class="content tp-header-content">
            <div class="tp-time-exchangerate">
              <div class="t-e-sub">
                <div class="time-text">{{ $t('app.chinaTime' || '中国时间') }}::</div>
                <div class="time-value">{{ currentTime }}</div>
              </div>
              <div class="t-e-sub">
                <div class="time-text">{{ $t('app.exchangeRate' || '汇率') }}:</div>
                <div class="time-value">{{ exchangeRate }}</div>
              </div>
            </div>
            <!-- 语言切换 -->
            <div class="language-switch">
              <a-select ref="select" v-model:value="current" style="width: 120px" :options="items"
                @change="handleChange"></a-select>
            </div>
          </div>
        </div>
        <!-- 搜索框 -->
        <page-header v-if="noUseTemplatePageRouter.indexOf(path) == -1" v-model:showAbout="isAboutVisible"
          v-model:activeIndex="aboutActiveIndex" v-model:keyword="keyword"></page-header>
        <!-- 面包屑 -->
        <page-bread v-if="noUseTemplatePageRouter.indexOf(path) == -1"
          v-model:showBreadcrumb="isBreadcrumbVisible"></page-bread>
      </div>
      <div class="tp-content" v-if="!(path === '/login' || path === '/register' || path === '/reset-password')">
        <div style="height: var(--total-header-height)" v-if="isBreadcrumbVisible"></div>
        <div style="height: var(--stable-header-height);" v-else></div>
        <div class="tp-content-body">
          <router-view />
        </div>
      </div>
      <div v-else>
        <router-view />
      </div>
      <!-- 页尾-联系信息 -->
      <div class="tp-footer" v-if="noUseTemplatePageRouter.indexOf(path) == -1">
        <page-footer></page-footer>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import i18n from "@/lang/index.js";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import moment from "moment"
import api from "./api/http"
import { defineComponent, onMounted, h, ref } from 'vue';
import { GlobalOutlined } from '@ant-design/icons-vue';
import PageHeader from './components/PageHeader.vue';
import PageBread from './components/PageBread.vue';
import PageFooter from './components/PageFooter.vue';
import store from "@/store";
import { HttpStatusCode } from "axios";
export default defineComponent({
  data() {
    return {
      locale: zhCN,
      path: '',
      mate: null,
      moment: moment,
      currentTime: null,
      exchangeRate: '1CNY=1.0981HKD',
      current: ref([this.$store.state.locale]),
      items: ref([
        {
          label: '简体中文',
          value: 'zh_cn',
        },
        {
          label: '繁體中文',
          value: 'zh_tw',
        },
        {
          label: 'English',
          value: 'en',
        },
        {
          label: 'Tiếng Việt',
          value: 'vi',
        },
      ]),
      isAboutVisible: false,
      isBreadcrumbVisible: false,
      aboutActiveIndex: -1,
      keyword: null,
      noUseTemplatePageRouter: ['/login', '/register', '/reset-password'],
    };
  },
  watch: {
    $route(to, from) {
      this.path = to.path
      this.setMenu(to);
      this.setKeyword(to);
    }
  },
  created() {
    // 第一次进入页面时，修改tag的值
    this.setMenu(this.$route);
    this.setKeyword(this.$route);
    this.initShopInfo()
    i18n.global.locale = this.$store.state.locale
  },
  mounted() {
    console.log('mounted', this.$route.path)
    //this.initShopInfo()
    // this.updateTime()
    setInterval(() => {
      this.updateTime();
    }, 1000);
  },
  components: { PageHeader, PageBread, PageFooter },
  methods: {
    // 语言切换
    handleChange(locale) {
      i18n.global.locale = locale
      this.$store.commit("saveLocale", locale);
    },
    updateSearchBoxVisible(visiable, index) {
      this.isAboutVisible = visiable;
      this.aboutActiveIndex = index;
    },
    updateBreadcrumbVisible(visiable) {
      this.isBreadcrumbVisible = visiable;
    },
    updateKeyword(keyword) {
      this.keyword = keyword;
    },
    setMenu(route) {
      // console.log('new-route', route)
      if (route.meta.hasMenu) {
        this.isBreadcrumbVisible = true;
      }
    },
    setKeyword(route) {
      this.keyword = route.query?.keyword
    },
    updateTime() {
      this.currentTime = moment().format('YYYY/MM/DD HH:mm:ss');
    },
    padZero(num) {
      if (num < 10) {
        return `0${num}`;
      } else {
        return num;
      }
    },
    initShopInfo() {
      var req_data = {
        ID: 2,
        Page: 1,
        PageCount: 1
      }
      api.tp_request({
        data: req_data,
        name: "GetShop"
      }, (res) => {
        if (res.State) {
          this.$store.commit("saveShopInfo", JSON.parse(res.ReturnJson).ShopInfo[0]);
        }
      })


    }
  },
  setup() {
    let userInfo = ref(null);


    onMounted(() => {

      let tempStore = store;
      if (tempStore.state.token) {
        userInfo.value = JSON.parse(tempStore.state.userInfo);
      }
    });

    return {
      userInfo,
    };
  },
})
</script>

<style lang="css">
@import "./style/stylehome.css";
/*首页样式 */
@import "./style/base.css";
/* 公共样式 */
</style>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-print-color-scheme: light;
  color-scheme: light;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  background-color: #F9F9FB;
  color: #000;
  line-height: 1.55;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
