<template>
  <a-row justify="center" class="tp-footer-content">
    <a-col :span="12">
      <a-row>
        <a-col :flex="3">
          <a-row>
            <a-col :span="8">
              <div class="group">
                <div class="part-title">采购说明</div>
                <div class="link">如何搜索产品</div>
                <div class="link">如何付款</div>
                <div class="link">采购流程</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="group">
                <div class="part-title">物流说明</div>
                <div class="link">物流费用</div>
                <div class="link">配送方式</div>
                <div class="link">物流时间</div>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="group">
                <div class="part-title">获取支持</div>
                <div class="label">电话：{{ ShopInfo.Tel }}</div>
                <div class="label">邮箱：{{ ShopInfo.Email }}</div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :flex="2">
          <a-row>
            <a-col :span="8">
              <div class="group">
                <div class="part-title">在线服务</div>
                <div class="label">微信</div>
                <div class="link-image">
                  <div class="tp-image" style="width:100%">
                    <figure class="tp-image-figure">
                      <div class="tp-image-imagewrapper"><img
                          src="https://cdn.dajisaas.com/tenant/c27f7ebc7ea64fc99c77f2aaa313a2ec.png" alt="qrCode"
                          class="daji-image-image" style="object-fit:contain;width:100%;height:auto"></div>
                    </figure>
                  </div>
                </div>
                <div class="account">taopaihk</div>
              </div>
            </a-col>
            <a-col :span="8"></a-col>
            <a-col :span="8"></a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <a-row justify="center">
    <a-col :span="12">
      <div class="copyright-area">
        <div class="copyright-content">
          <div class="tp-ir">
            <div class="link">1688.com</div>
            <div class="tp-divider-vertical" role="separator"></div>
            <div class="link">Taobao</div>
            <div class="tp-divider-vertical" role="separator"></div>
            <div class="link">Mala Tao</div>
            <div class="tp-divider-vertical" role="separator"></div>
            <div class="link">Jingdong</div>
            <div class="tp-divider-vertical" role="separator"></div>
            <div class="link">Pinduoduo</div>
          </div>
          <div class="tp-dr">
            <div class="link">采购说明</div>
            <div class="tp-kcyr">·</div>
            <div class="link">物流说明</div>
            <div class="tp-kcyr">·</div>
            <div class="link">采购订单</div>
            <div class="tp-kcyr">·</div>
            <div class="link">仓库管理</div>
            <div class="tp-kcyr">·</div>
            <div class="link">用户协议</div>
            <div class="tp-kcyr">·</div>
            <div class="link">关于我们</div>
          </div>
          <div class="copyright">
            <div>© 2023 <!-- -->{{ ShopInfo.Name }}<!-- --> All rights reserved.{{ ShopInfo.RecordNo }}</div>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  data () {
    return {
      ShopInfo: this.$store.state.shopInfo,
    }
  },
  methods: {
    async login () {
      this.$router.push('/login');
    }
  },
};
</script>