<template>
  <div class="header-container">
    <div class="header-container-content">
      <!-- Logo -->
      <div class="logo-image" @click="goHome">
        <figure class="logo-figure">
          <div class="logo-Image-imageWrapper">
            <img src="../assets/logo.png" class="logo-Image-image">
          </div>
        </figure>
      </div>
      <!-- 搜索框 -->
      <div class="nav-area">
        <template v-if="showAbout">
          <div :class="['nav-item', { 'selected': activeIndex == index }]" v-for="(item, index) in aboutList"
            :key="index" @click="toggleSelected(index, item.url)">
            <div>{{ item.title }}</div>
          </div>
        </template>

        <form style="position:absolute;right:30px;top:0;bottom:0;margin:auto 0;z-index:2"
          :class="[showAbout ? 'form-search-right' : 'form-search']">
          <div class="input-area">
            <div class="camera-area"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24"
                aria-hidden="true" color="#388255" :style="{ color: 'var(--main-color)' }" height="28" width="28"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z">
                </path>
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
              </svg></div>
            <div style="width:1px;height:14px;background:#E2E8F0"></div>
            <div class="textinput-root">
              <div class="textinput-wrapper">
                <input class="textinput-input" id="mantine-qw2ozxu06" type="text" placeholder="商品关键词或商品链接，支持淘宝、天猫、1688"
                  aria-invalid="false" :value="keyword" @input="keywordInputHandler" />
                <div class="input-rightsection" v-if="keyword" @click="rightClickHandler">
                  <div class="right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18"
                      fill="currentColor">
                      <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z">
                      </path>
                    </svg></div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-button-placeholder"></div>
          <button :class="['button-root', showAbout ? 'btn-root-right' : 'btn-root']" @click.prevent="search"
            data-button="true">
            <div class="button-inner"><span class="button-label"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16" width="20" height="20" fill="currentColor">
                  <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                  </path>
                </svg></span></div>
          </button>
          <div class="upload-imagelayer"
            style="position: fixed; height: 0px; opacity: 0; pointer-events: none; left: 497px; top: 52px; width: 852.188px;">
            <div class="daji-Text-root daji-xyxd63">通过图片搜索产品</div>
            <div class="daji-Dropzone-root dropzone daji-1gre9yl"
              style="align-self:stretch;margin-top:20px;background:transparent;padding:0" role="presentation"
              tabindex="0" data-idle="true"><input accept="image/jpeg,image/png" type="file" style="display:none"
                tabindex="-1">
              <div class="daji-1mmllzu daji-Dropzone-inner">
                <div class="centerInfo"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                    viewBox="0 0 512 512" height="30" width="30" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M450.29 112H142c-34 0-62 27.51-62 61.33v245.34c0 33.82 28 61.33 62 61.33h308c34 0 62-26.18 62-60V173.33c0-33.82-27.68-61.33-61.71-61.33zm-77.15 61.34a46 46 0 11-46.28 46 46.19 46.19 0 0146.28-46.01zm-231.55 276c-17 0-29.86-13.75-29.86-30.66v-64.83l90.46-80.79a46.54 46.54 0 0163.44 1.83L328.27 337l-113 112.33zM480 418.67a30.67 30.67 0 01-30.71 30.66H259L376.08 333a46.24 46.24 0 0159.44-.16L480 370.59z">
                    </path>
                    <path
                      d="M384 32H64A64 64 0 000 96v256a64.11 64.11 0 0048 62V152a72 72 0 0172-72h326a64.11 64.11 0 00-62-48z">
                    </path>
                  </svg>
                  <div class="daji-Text-root daji-fz07f0"><span>将图片拖到这里</span></div>
                </div>
              </div>
            </div><button class="daji-UnstyledButton-root daji-Button-root daji-14cox5x" type="button"
              data-button="true">
              <div class="daji-1wpc1xj daji-Button-inner"><span class="daji-1ryt1ht daji-Button-label">上传图片</span>
              </div>
            </button>
          </div>
        </form>
      </div>
      <div class="sep-line"></div>
      <!-- 购物车 -->
      <div class="shop-cart heard-button">
        <button class="sc-button-root">
          <div class="group-root">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20px" height="20px" fill="currentColor">
              <path
                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z">
              </path>
            </svg>
            <div class="content">购物车</div>
          </div>
        </button>
      </div>
      <div class="sep-line"></div>
      <!-- 登录/注册 -->
      <div class="login-register heard-button">
        <button class="sc-button-root">
          <div class="group-root">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24px" height="24px" fill="currentColor">
              <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z">
              </path>
            </svg>
            <template v-if="ClientInfo != null">
              <a-popover placement="bottom" @click="login" overlayClassName="tp-login-btn">
                <template #content v-if="ClientInfo != null">
                  <a-button type="text" class="pick-btn" @click="jump(1, 'message')">{{ $t('pageMenu.message') || '我的消息'
                    }}</a-button>
                  <a-button type="text" class="pick-btn" @click="jump(1, 'purchase')">{{ $t('pageMenu.purchase') ||
        '采购订单' }}</a-button>
                  <a-button type="text" class="pick-btn" @click="jump(1, 'my-billcode')">{{ $t('pageMenu.billcode') ||
        '我的包裹' }}</a-button>
                  <a-button type="text" class="pick-btn" @click="jump(1, 'transite')">{{ $t('pageMenu.transite') ||
        '转运订单' }}</a-button>
                  <!-- <a-button type="text" class="pick-btn" @click="jump(1, 'pending-payments')">批量付款</a-button> -->
                  <a-button type="text" class="pick-btn" @click="jump(1, 'balance')">{{ $t('pageMenu.balance') || '余额管理'
                    }}</a-button>
                  <a-button type="text" class="pick-btn" @click="jump(1, 'account')">{{ $t('pageMenu.account') || '账户设置'
                    }}</a-button>
                  <a-button type="text" class="pick-btn" @click="jump(2, 'loginOut')">{{ $t('pageMenu.loginOut') ||
        '退出登录' }}</a-button>
                </template>
                <div class="content">{{ UserInfo }}</div>
              </a-popover>
            </template>
            <template v-else>
              <div class="content" @click="login">登录/注册</div>
            </template>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: -1,
      ClientInfo: this.$store.state.userInfo,
      shopInfo: this.$store.state.shopInfo,
      UserInfo: '',
      aboutList: [],
      showAboutPath: ['/how-to-use', '/transportation-charge', '/about'],
    };
  },
  props: ['showAbout', 'activeIndex', 'keyword'],
  computed: {
  },
  mounted() {
    if (this.shopInfo) {
      this.aboutList = [
        { title: "外贸代理采购服务", url: '/' },
        { title: "采购转运流程介绍", url: '/how-to-use' },
        { title: "物流转运费用说明", url: '/transportation-charge' },
        { title: "关于" + this.shopInfo.Name, url: '/about' }
      ]
    }
    if (this.ClientInfo) {
      console.log(this.ClientInfo)
      this.UserInfo = this.ClientInfo.Name
    }
    this.initShowAbout()
  },
  methods: {
    initShowAbout() {
      console.log(this.showAbout)
    },
    async login() {
      debugger
      if (!this.ClientInfo) {
        this.$router.push('/login');
      }

    },
    toggleSelected(index, _url) {
      if (index == 0) {
        this.$emit("update:showAbout", false);
        this.$router.push({ name: "home" });
      } else {
        this.$emit("update:activeIndex", index);
      }
      this.$router.push(_url);
    },
    // 搜索框点击事件
    async search(event) {
      if (this.showAbout) {
        this.$emit("update:showAbout", false);
      } else {
        this.queryProductList();
      }
    },
    // 查询商品信息
    queryProductList() {
      this.$router.push({ name: "product_search", query: { keyword: this.keyword, channel: 'alibaba' } })
    },
    // 下拉菜单跳转
    jump(type, routeName) {
      if (type == 1) {
        this.$router.push(routeName);
        this.$root.updateBreadcrumbVisible(true)
      }
      else {
        window.localStorage.removeItem('UserInfo')
        this.$router.push('/login')
      }
    },
    // 搜索框值改变事件
    keywordInputHandler(e) {
      this.$emit("update:keyword", e.target.value)
      console.log(e.target.value);
    },
    // 点击删除
    rightClickHandler() {
      this.$emit("update:keyword", '')
    },
    goHome() {
      this.$router.push('/')
    }
  },
  watch: {
    $route(to, from) {
      let result = this.showAboutPath.indexOf(to.path);
      if (result != -1) {
        this.$emit("update:showAbout", true);
        this.$emit("update:activeIndex", (result + 1));
      }
      else {
        this.$emit("update:showAbout", false);
      }

    }
  }
};
</script>

<style scoped>
.pick-btn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-tap-highlight-color: transparent;
  font-size: 0.875rem;
  border: 0px;
  background-color: transparent;
  outline: 0px;
  width: 100%;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  padding: 0.625rem 0.75rem;
  cursor: pointer;
  border-radius: 12px;
  color: rgb(0, 0, 0);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: initial;
}

.pick-btn:hover {
  background-color: rgb(241, 243, 245);
}

.pick-btn /deep/ span {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  padding: 6px 12px 6px 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55;
  color: black;
}
</style>

<style>
.tp-login-btn .ant-popover-content .ant-popover-inner {
  padding: 0;
}

.tp-login-btn .ant-popover-inner-content {
  padding: 4px;
}
</style>